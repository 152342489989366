import { Popconfirm, Table, message } from "antd";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StarIcon from "@mui/icons-material/Star";
import { useEffect, useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Apis from "apis/remotes";
import { IMatchApply, ApplicantCurrentStatusType, WorkStatusType } from "apis/response";
import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { calculateAge } from "utils/utilDate";

import MDButton from "components/MDButton";
import RenderUserScore from "components/RenderUserScore";
import { inputToPhone } from "utils/PhoneString";
import MDBadge from "components/MDBadge";

interface Props {
  workId: string | number;
  workEndAuto: boolean;
  workStatus: WorkStatusType;
  handleUpdateAnnouncementStatus: Function;
  // handleUpdateWorkOff: (workId: string | number) => void;
}
/** 지원확인 컴포넌트 */
function ApplicationReview({
  workId,
  workEndAuto,
  handleUpdateAnnouncementStatus,
  workStatus,
}: Props): JSX.Element {
  console.log("ApplicationReview Show");
  const [applicants, setApplicants] = useState<IMatchApply[]>([]);
  const [loading, setLoading] = useState(false);

  const handleUpdateMatchApprove = (applicantId: string | number) => {
    Apis.putMatchApprove(applicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.map((applicant) => {
            if (applicant.userApplicantId === applicantId) {
              return { ...applicant, status: "지원합격" };
            } else {
              return applicant;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateMatchabsenceCancelById = (userApplicantId: string | number) => {
    Apis.putMatchAbsenceCancelById(userApplicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.filter((applicant) => applicant.userApplicantId !== userApplicantId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** 공고 마감 버튼 클릭 */
  const handleUpdateWorkOff = (workId: string | number) => {
    Apis.putWorkOff(workId)
      .then((resolve) => {
        message.success("해당 공고가 마감되었습니다.");
        handleUpdateAnnouncementStatus();
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "");
      });
  };
  /** 향후 지원 제한 */
  const handleUpdateMatchRejectRecruit = (applicantId: string | number) => {
    Apis.putMatchRejectPenalty(applicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.map((applicant) => {
            if (applicant.userApplicantId === applicantId) {
              return { ...applicant, status: "지원미승인" };
            } else {
              return applicant;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** 오늘만 거절 */
  const handleUpdateMatchOnceReject = (applicantId: string | number) => {
    Apis.putMatchReject(applicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.map((applicant) => {
            if (applicant.userApplicantId === applicantId) {
              return { ...applicant, status: "지원미승인" };
            } else {
              return applicant;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** 공고 자동마감 ON OFF 버튼 클릭 */
  const handlePutHandleOffWork = (workId: string | number, workEndAuto: boolean) => {
    setLoading(true);
    Apis.putWorkOnOffManual(workId)
      .then((resolve) => {
        const resultMessage = workEndAuto
          ? "해당 공고가 수동 마감으로 전환되었습니다."
          : "해당 공고가 자동 마감으로 전환되었습니다.";
        message.success(resultMessage);
        handleUpdateAnnouncementStatus();
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableColumn: ColumnsType<IMatchApply> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
      render: (name: string, record) => (
        <MDBox textAlign={"center"}>
          {record.isDeleted ? (
            <p style={{ color: "red", fontSize: 12 }}>탈퇴회원</p>
          ) : record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null}
          {/* {record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null} */}
          <span>{name}</span>
        </MDBox>
      ),
    },
    {
      title: "고용보험",
      dataIndex: "insuranceDay",
      key: "insuranceDay",
      align: "center",
      render: (insuranceDay: number) => {
        if (insuranceDay === 0) {
          return <MDBadge badgeContent="미인증" container size="sm" color="error" />;
        } else {
          return <MDBadge badgeContent="인증" container size="sm" color="success" />;
        }
      },
    },
    {
      title: "평점",
      dataIndex: "userScore",
      key: "userScore",
      render: RenderUserScore,
    },

    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      render: (birth: string) => calculateAge(birth),
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },

    {
      title: (
        <p>
          해당 현장 <br />
          출근 수
        </p>
      ),
      dataIndex: "targetWorkCount",
      key: "targetWorkCount",
      align: "center",
      render: (targetWorkCount: number) =>
        targetWorkCount > 0 ? (
          `${targetWorkCount}회 출근`
        ) : (
          <Typography color="error" variant="h6">
            최초 출근
          </Typography>
        ),
    },
    {
      title: (
        <p>
          최근 10회 <br />
          근무 결근 수
        </p>
      ),
      dataIndex: "recentAbsenceCount",
      key: "recentAbsenceCount",
      align: "center",
      render: (recentAbsenceCount: number) => {
        switch (recentAbsenceCount) {
          case 0:
            return "-";
          case 1:
            return 1;
          case 2:
            return 2;
          default:
            return (
              <Typography color="error" variant="h6">
                {recentAbsenceCount}
              </Typography>
            );
        }
      },
    },
    {
      title: "혈압",
      dataIndex: "bloodPressure",
      key: "bloodPressure",
      align: "center",
    },
    {
      title: "출근 승인",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: ApplicantCurrentStatusType, record) => {
        switch (status) {
          case "지원완료":
            return (
              <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} gap={2}>
                <Popconfirm
                  cancelText="취소"
                  okText="승인"
                  icon={<QuestionCircleOutlined style={{ color: "green" }} />}
                  title="지원자를 합격하시겠습니까?"
                  description="승인하시면 합격한 지원자는 현장관리에서 확인할 수 있습니다."
                  onConfirm={() => handleUpdateMatchApprove(record.userApplicantId)}
                >
                  <MDButton color="info" variant="gradient" disabled={status !== "지원완료"}>
                    승인
                  </MDButton>
                </Popconfirm>

                <Popconfirm
                  cancelText="오늘만 거절"
                  okText="패널티 거절"
                  okButtonProps={{
                    danger: true,
                  }}
                  cancelButtonProps={{
                    danger: true,
                  }}
                  icon={null}
                  title=""
                  onConfirm={() => handleUpdateMatchRejectRecruit(record.userApplicantId)}
                  onCancel={() => {
                    handleUpdateMatchOnceReject(record.userApplicantId);
                  }}
                >
                  <MDButton color="warning" variant="gradient" disabled={status !== "지원완료"}>
                    거절
                  </MDButton>
                </Popconfirm>
              </MDBox>
            );
          case "결근":
            return (
              <MDButton
                size="small"
                color="error"
                variant="gradient"
                onClick={() => handleUpdateMatchabsenceCancelById(record.userApplicantId)}
              >
                출근 상태로 변경
              </MDButton>
            );
          default:
            return status;
        }
      },
    },
  ];

  useEffect(() => {
    Apis.getMatchCurrentApply(workId, { status: "apply" })
      .then((resolve) => {
        setApplicants(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [workId]);
  return (
    <MDBox>
      <MDBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography variant="h5">지원확인</MDTypography>
        <MDBox gap={1} display={"flex"}>
          <MDButton
            color={workEndAuto ? "dark" : "light"}
            onClick={() => handlePutHandleOffWork(workId, workEndAuto)}
            disabled={loading}
          >
            {workEndAuto ? "수동 마감으로 전환" : "자동 마감으로 전환"}
          </MDButton>
          <Popconfirm
            title={"해당 공고를 마감하겠습니까?"}
            icon={null}
            okText="마감"
            cancelText="취소"
            onConfirm={() => handleUpdateWorkOff(workId)}
          >
            <MDButton color="dark" variant="gradient" disabled={workStatus === "모집완료"}>
              공고마감
            </MDButton>
          </Popconfirm>
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <Table
          pagination={false}
          rowKey={(record) => record.userApplicantId}
          columns={tableColumn}
          dataSource={applicants}
          scroll={{ x: "max-content" }}
        />
      </MDBox>
    </MDBox>
  );
}
export default ApplicationReview;
